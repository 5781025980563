<template>
  <section>
    <v-carousel
      cycle
      hide-delimiter-background
      :show-arrows="false"
      delimiter-icon="mdi-minus"
      max-height="420"
      height="auto"
    >
      <v-carousel-item
        max-height="420"
        v-for="(slider, i) in sliders"
        :key="i"
        :src="slider.img"
      ></v-carousel-item>
    </v-carousel>
    <v-container text-center class="ctk-div">
      <v-layout wrap>
        <v-flex xs12 md6 mb-12 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/products">
              <v-img
                v-if="info.bannerProduct[0]"
                :src="info.bannerProduct[0].img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>产品中心</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 mb-12 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/cases">
              <v-img
                v-if="info.bannerCase[0]"
                :src="info.bannerCase[0].img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>应用案例</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md4 mb-12 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/trainings/2">
              <v-img
                src="imgs/xfsp.jpeg"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>修复视频</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md4 mb-12 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/about">
              <v-img
                v-if="info.bannerAbout[0]"
                :src="info.bannerAbout[0].img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>关于柯尼</h1>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md4 mb-12 pa-2 class="ctk-jx-category">
          <v-card class="mx-auto ctk-case-category-card" >
            <a href="/contact">
              <v-img
                v-if="info.bannerContact[0]"
                :src="info.bannerContact[0].img"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>联系我们</h1>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    sliders: null,
    pageId: 0,
    tabProducts: null,
    tabNews: null,
    tabCases: null,
    innerWidth: window.innerWidth,
    detail: null,
    publish: null
  }),
  created () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.sliders = val.banner[this.pageId]
        })
      },
      deep: true
    }
  },
  computed: {
    info () {
      let sliders = { '0': [] }
      let bannerProduct = {}
      let bannerCase = {}
      let bannerAbout = {}
      let bannerContact = {}
      /*
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      */
      if (this.$store.getters.companyInfo.banner) {
        sliders = this.$store.getters.companyInfo.banner[this.pageId]
        bannerProduct = this.$store.getters.companyInfo.banner[2]
        bannerCase = this.$store.getters.companyInfo.banner[3]
        bannerAbout = this.$store.getters.companyInfo.banner[10]
        bannerContact = this.$store.getters.companyInfo.banner[6]
      }
      return {
        sliders: sliders,
        bannerProduct: bannerProduct,
        bannerCase: bannerCase,
        bannerAbout: bannerAbout,
        bannerContact: bannerContact
      }
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
